<template>
    <RouterView
        v-if="isMuvalUser"
        v-slot="{ Component, route }"
        name="agent"
    >
        <component
            :is="Component"
            v-if="route.meta?.fullscreen"
        />
        <AuthenticatedLayout v-else>
            <component :is="Component" />
        </AuthenticatedLayout>
    </RouterView>
    <RouterView
        v-else-if="isRelocationsUser"
        v-slot="{ Component, route }"
        name="relocations"
    >
        <component
            :is="Component"
            v-if="route.meta?.fullscreen"
        />
        <AuthenticatedLayout v-else>
            <component :is="Component" />
        </AuthenticatedLayout>
    </RouterView>
    <RouterView
        v-else
        v-slot="{ Component, route }"
    >
        <component
            :is="Component"
            v-if="route.meta?.fullscreen"
        />
        <AuthenticatedLayout v-else>
            <component :is="Component" />
        </AuthenticatedLayout>
    </RouterView>
    <ToastNotification
        v-if="showLayout"
        class="z-50"
    />
</template>
<script setup>
const appStore = useAppStore();
const userStore = useUserStore();

const route = useRoute();

const isMuvalUser = computed(() => {
    return userStore.hasAnyRole(['MUVAL_AGENT', 'MUVAL_ADMIN', 'MUVAL_TRIAGE', 'MUVAL_COMPANY', 'SUPER']);
});

const isRelocationsUser = computed(() => {
    return userStore.hasAnyRole(['RELOCATIONS_MANAGER']);
});

const showLayout = computed(() => {
    return (
        userStore.isAuthenticated &&
        route.name !== 'Login' &&
        route.name !== 'Register' &&
        route.name !== 'Update your preferences'
    );
});

onMounted(async () => {
    appStore.setDeviceType();
    await appStore.initGoogle();
});
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px;
    padding: 5px;
    border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    padding: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    padding: 5px;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c4c458;
}
</style>
